import styled from "styled-components";

export const SectionStyled = styled.div`
  > .title {
    background-color: #dcdddf;
    padding: 0 0.5rem;
    margin-bottom: 1rem;
    font-weight: 700;
    font-size: 1.5rem;
  }
`;
