import { FC } from "react";
import { SectionStyled } from "./styles";

interface ISectionProps {
  title: string;
  children: JSX.Element;
}

export const Section: FC<ISectionProps> = ({ title, children }) => {
  return (
    <SectionStyled>
      <div className="title">{title}</div>
      {children}
    </SectionStyled>
  );
};
