import styled from "styled-components";

export const StyledResume = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    .name {
      margin-bottom: 0;
      margin-top: 0;
    }
    .position {
      margin-bottom: 0;
    }
  }
  .experience {
    display: flex;
    .info {
      flex: 1 1 85%;
    }
    .years {
      flex: 1 1 15%;
    }
  }
  .tech-skill {
    font-style: italic;
    margin-bottom: 0.5rem;
    .title {
      font-weight: 700;
    }
    .list {
      margin-left: 2rem;
    }
  }
  .education {
    margin-bottom: 1rem;
    .program-name {
      font-weight: 700;
    }
  }
  .work-experience {
    margin-bottom: 2rem;
    .company-name {
      font-size: 1.25rem;
      font-weight: 700;
    }
    .position {
      font-size: 1.25rem;
      font-weight: 700;
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.5rem;
    }
    .technologies {
      font-style: italic;
      > span {
        font-weight: 700;
      }
    }
  }
`;
